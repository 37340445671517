'use client';

import { Stack } from '@mui/material';
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';
export default function Error() {
  return <Stack flexDirection="column" justifyContent="space-between" alignItems="center" data-sentry-element="Stack" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <BrokenImageOutlinedIcon sx={{
      width: '42px',
      height: '42px',
      color: '#593E95',
      marginBottom: 4
    }} data-sentry-element="BrokenImageOutlinedIcon" data-sentry-source-file="error.tsx" />
      <Stack fontWeight={500} fontSize="32px" data-sentry-element="Stack" data-sentry-source-file="error.tsx">
        There seems to be a problem with our system
      </Stack>
      <Stack fontWeight={400} fontSize="16px" data-sentry-element="Stack" data-sentry-source-file="error.tsx">
        We’re experiencing an internal server problem. Try again later.
      </Stack>
    </Stack>;
}